@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'roboto';
}

.logo{
    transform: scale(1.3);
}

body{
    overflow-x: hidden;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
}


:root{
    --main-bg:#4e23df;
}
