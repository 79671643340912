.home{
    position: relative;
}

.home .home_navbar{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:sticky;
    top: 0;
    left: 0;
    z-index: 999;
}