.app{
  max-width: 1920px;
  margin: auto;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
*::-webkit-scrollbar-thumb {
  background: #444; 
  border-radius: 10px;
}

