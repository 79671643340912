.categories{
  /* width: 80%; */
  height: 47px;
  border-radius: 0 0 15px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 12px rgb(138, 138, 138);
  background-color: #fff8;
  backdrop-filter:  blur(20px);   /* saturate(180%) */
  padding: 0 2%;
}

.categories_item{
  color: #000;
  font-size: 20px;
  border-radius: 10px;
  padding:6px 12px;
  margin: 0 10px;
}

.categories_active{
  background-color: var(--main-bg);
  color:#fff;
}


