.products_caption{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-bg);
    font-size: 20px;
    margin-bottom: 25px;
}

.products_caption .hr{
    flex: 1;
    height: 5px;
    background-color:var(--main-bg);
    margin-left: 10px;
}