.MainHeaderSwiperWrapper {
  width: 80%;
  max-height: 100vh;
  background-color: red;
  cursor: pointer;
  display: grid;
  place-items: center;
  margin: 20px 0;
}

.swiperSlide {
  display: inherit;
}

.banner_carousel_img {
  width: 100%;

}