.products{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 1.7% 0;
}

.products_wrapper{
    display: flex;
    flex-wrap: wrap;
    background: url(../../assets/bg.webp);
}

.products_item{
    width: 350px;
    height: 385px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #3333;
    margin: 15px;
    transition: 0.2s all;
    padding-bottom: 15px;
}

.products_item:hover{
    box-shadow:3px 5px 19px -4px rgba(34, 60, 80, 0.6);
    transition: 0.2s all;
    transform-origin: bottom;
}

.products_item a img{
    width: 100%;
    height: 70%;
    object-fit: contain;
    margin: auto;
    margin-bottom: 10px;
    display: block;
}

.products_item p{
    margin-left: 5%;
}

.products_item_title{
    font-size: 22px;
}

.products_item_price{
    font-size: 25px;
    font-weight: 600;
}

.products_item_btns{
    margin-top: 10px;
    margin-left: 5%;
}

.products_item_btns svg{
    color: var(--main-bg);
    font-size: 35px;
    margin-right: 20px;
    cursor: pointer;
}

.products_item_btns svg:active{
    transform: scale(.9);
}

